import { SelectNewOption } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  FeeCatalog,
  FundingSource,
  FundingSourceType,
  PaymentRestrictions,
  PaymentSettingsCalculations,
} from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';

import { FundingSourceSectionProps } from './FundingSourceSection';
import { useAddFundingSourceButtonProps } from './useAddFundingSourceButtonProps/useAddFundingSourceButtonProps';
import { useFilteredFundingSources } from './useFilteredFundingSources/useFilteredFundingSources';
import { useFundingSourcesSearchBar } from './useFundingSourceSearchBar/useFundingSourceSearchBar';
import { getDefaultSelectedType } from './util';

type Options = {
  formContext: { isEditFlow: boolean; submitting: boolean };
  onFundingSourceChange: (fundingSourceId: string | undefined) => void;
  paymentSettings: PaymentSettingsCalculations | undefined;
  fundingSources: FundingSource[];
  feeCatalog: FeeCatalog[] | undefined;
  fundingSourceId: string | undefined;
};

export function useFundingSourceSection({
  fundingSources: allFundingSources,
  fundingSourceId,
  onFundingSourceChange,
  paymentSettings,
  feeCatalog,
  formContext,
}: Options): FundingSourceSectionProps {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      fundingSourcePolicy,
      payment: {
        supportedFundingSourceTypes,
        scheduling: { fundingSourceTypeSelectionEnabled },
        editing: { fundingSourceTypeSelectionEnabledOnEdit },
      },
    },
  } = useConfig();

  const { fundingSources, card, bank, paypalBalance } = useFilteredFundingSources({
    fundingSourceTypesOptions: paymentSettings?.fundingSourceTypesOptions,
    fundingSources: allFundingSources,
    paymentRestrictions: paymentSettings?.restrictions,
  });

  const fsEligibility = paymentSettings?.restrictions?.fsEligibility;
  const [type, setType] = useState<FundingSource['type']>(getDefaultSelectedType(fundingSourcePolicy));
  const selectedFundingSource = fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);
  const fundingSourcesByType = fundingSources.filter((fundingSource) => fundingSource.type === type);
  const dropdownOptions = fundingSourcesByType.map((fs) => toOption(fs, isEligible(fs, fsEligibility)));
  useEffect(() => {
    if (selectedFundingSource) {
      setType(selectedFundingSource.type);
    }
  }, [selectedFundingSource]);

  const {
    props: searchProps,
    show: showSearch,
    filtered: searchedFundingSources,
  } = useFundingSourcesSearchBar(fundingSourcesByType);
  const searchedOptions = searchedFundingSources.map((fs) => toOption(fs, isEligible(fs, fsEligibility)));

  const hideTypeToggle =
    !fundingSourceTypeSelectionEnabled ||
    (formContext.isEditFlow && !fundingSourceTypeSelectionEnabledOnEdit) ||
    Object.values(supportedFundingSourceTypes).filter(Boolean).length === 1;

  const isReadOnly = formContext.submitting;

  const selectorAddButtonProps = useAddFundingSourceButtonProps(type);

  return {
    radioToggle: {
      isReadOnly,
      hide: hideTypeToggle,
      onChange: (event) => {
        const selectedType = event.target.value as FundingSource['type'];
        const nonDisabledExistingFundingSourceOption = fundingSources.find(
          (fs) => fs.type === selectedType && isEligible(fs, fsEligibility)
        );
        track('PaymentMethod', 'Click', {
          Intent: 'set-payment-method',
          Cta: `${selectedType}-radio-button`,
          PaymentMethodId: nonDisabledExistingFundingSourceOption?.id,
        });
        onFundingSourceChange(nonDisabledExistingFundingSourceOption?.id);
        setType(selectedType);
      },
      options: compact([
        supportedFundingSourceTypes[FundingSourceType.PaypalBalance]
          ? {
              value: FundingSourceType.PaypalBalance,
              label: {
                label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.paypal-balance.type'),
                isReadOnly,
                isDisabled: paypalBalance.disabled,
                tooltip: paypalBalance.disabledMessage,
                tooltipVisuallyHiddenPrefix: formatMessage(
                  'activities.paymentFlow.form.content.fundingSourceCard.paypal-balance.type.tooltip'
                ),
                dataTestId: `fundingSource-${FundingSourceType.PaypalBalance}-disabled-indicator`,
              },
              ariaLabel: formatMessage(
                'activities.paymentFlow.form.content.fundingSourceCard.paypal-balance.type.aria'
              ),
              disabled: bank.disabled,
            }
          : null,
        supportedFundingSourceTypes[FundingSourceType.BankAccount]
          ? {
              value: FundingSourceType.BankAccount,
              label: {
                label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.type'),
                isReadOnly,
                isDisabled: bank.disabled,
                tooltip: bank.disabledMessage,
                tooltipVisuallyHiddenPrefix: formatMessage(
                  'activities.paymentFlow.form.content.fundingSourceCard.bank.type.tooltip'
                ),
                dataTestId: `fundingSource-${FundingSourceType.BankAccount}-disabled-indicator`,
              },
              ariaLabel: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.type.aria'),
              disabled: bank.disabled,
            }
          : null,
        supportedFundingSourceTypes[FundingSourceType.Card]
          ? {
              value: FundingSourceType.Card,
              label: {
                label: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type'),
                isReadOnly,
                isDisabled: card.disabled,
                tooltip: card.disabledMessage,
                tooltipVisuallyHiddenPrefix: formatMessage(
                  'activities.paymentFlow.form.content.fundingSourceCard.card.type.tooltip'
                ),
                dataTestId: `fundingSource-${FundingSourceType.Card}-disabled-indicator`,
              },
              ariaLabel: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.aria'),
              disabled: card.disabled,
            }
          : null,
      ]),
    },
    selector: {
      value: selectedFundingSource,
      isReadOnly,
      options: dropdownOptions,
      searchBarProps: showSearch ? { ...searchProps, options: searchedOptions } : undefined,
      onChange: (event) => {
        const fundingSource = event.target.value as unknown as FundingSource;
        track('PaymentMethod', 'Click', {
          Intent: 'set-payment-method',
          Cta: fundingSource.type,
          PaymentMethodId: fundingSource.id,
        });
        onFundingSourceChange(fundingSource.id);
      },
      addButton: selectorAddButtonProps,
    },
    selectedType: type,
    isLoading: false,
    feeCatalog,
  };
}

const isEligible = (fundingSource: FundingSource, fsEligibility?: PaymentRestrictions['fsEligibility']): boolean =>
  fsEligibility?.find((efs) => efs.id === fundingSource.id)?.eligible ?? false;
const toOption = (fundingSource: FundingSource, isEligible: boolean): SelectNewOption<FundingSource> => ({
  value: fundingSource,
  label: fundingSource.displayName,
  disabled: isEligible ? undefined : { isDisabled: true },
});
