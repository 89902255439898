import { useInvalidFundingSources } from '@melio/ap-domain';
import { FsTypeOption, FundingSource, FundingSourceType, PaymentRestrictions } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const useFilteredFundingSources = ({
  fundingSources,
  fundingSourceTypesOptions,
  paymentRestrictions,
}: {
  fundingSources: FundingSource[];
  fundingSourceTypesOptions: FsTypeOption[] | undefined;
  paymentRestrictions: PaymentRestrictions | undefined;
}) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      partnerDisplayName,
      payment: {
        scheduling: { removeIneligibleFundingSources, disableFundingSourceTypeToggleIfNoOptions },
      },
    },
  } = useConfig();

  const { getIsValidFundingSource } = useInvalidFundingSources(fundingSourceTypesOptions);

  const validFundingSources = fundingSources.filter(getIsValidFundingSource);
  const eligibleFundingSources =
    paymentRestrictions?.fsEligibility && removeIneligibleFundingSources
      ? validFundingSources.filter((fundingSource) =>
          paymentRestrictions?.fsEligibility?.some((efs) => efs.id === fundingSource.id && efs.eligible)
        )
      : validFundingSources;

  const cardDisabledMessage = ((): string | undefined => {
    const hasCards = !!fundingSources.find(({ type }) => type === FundingSourceType.Card);
    if (!hasCards) {
      return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled', {
        partnerName: partnerDisplayName,
      });
    }

    const hasValidCards = !!validFundingSources.find(({ type }) => type === FundingSourceType.Card);
    if (!hasValidCards) {
      return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled.invalid');
    }

    const hasEligibleCards = !!eligibleFundingSources.find(({ type }) => type === FundingSourceType.Card);
    if (!hasEligibleCards) {
      return formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.type.disabled.ineligible');
    }

    return;
  })();

  const isFundingSourceDisabled = (fundingSourceType: FundingSourceType) => {
    const foundFundingSource = eligibleFundingSources.find((fundingSource) => fundingSource.type === fundingSourceType);
    return !foundFundingSource && disableFundingSourceTypeToggleIfNoOptions;
  };

  const isCardDisabled = isFundingSourceDisabled(FundingSourceType.Card);
  const isBankDisabled = isFundingSourceDisabled(FundingSourceType.BankAccount);

  return {
    fundingSources: eligibleFundingSources,
    card: {
      disabled: isCardDisabled,
      disabledMessage: isCardDisabled ? cardDisabledMessage : undefined,
    },
    bank: {
      disabled: isBankDisabled,
      disabledMessage: undefined,
    },
    paypalBalance: {
      disabled: false,
      disabledMessage: undefined,
    },
  };
};
